.taiga {
  .login-form {
    width: 300px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: azure;
    padding: 30px;
    border: solid 1px #adf0ff;
  }

  table {
    th,td {
      padding: 5px !important;
    }
  }

  .projects-list {
    margin: 10px 0;

    .project {
      .description-concat {
        position: relative;
        display: inline-block;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.6em; /* (Number of lines you want visible) * (line-height) */
        line-height: 1.2em;
        text-align:justify;
      }

      //.description.ellipsis::after {
      //  content: "...";
      //  position: absolute;
      //  right: -12px;
      //  bottom: 4px;
      //}
    }
  }

  .epics {
    .epic {
      background-color: azure;
    }

    .user-stories {
      padding: 20px 0;
    }
  }

  .project-summary {
    .logo {
      margin-right: 12px;
    }
  }

  .sprints {
    .us-progress {
      .anticon {
        padding: 0 2px;
      }

      .avatar {
        max-width: 32px;
        max-height: 32px;
        margin: 0 2px;
      }
    }

    h4 {
      text-align: center;
      margin-top: 30px;
    }

    h4.future {
      color: #52c41a;
    }

    h4.past {
      color: #a6a6a6;
    }

    h4.in-progress {
      color: #d9363e;
    }
  }

  .team {
    .sprint {
      .user_stories {
        padding: 0;

        li {
          list-style: none;
          margin: 2px;
          padding: 3px;
          background-color: #f9f9fb;
          border-bottom: 1px solid #d8dee9;
        }
      }
    }
  }

  .time-table-calendar {
    .task-row {
      border-bottom: solid 1px #d8dee9;

      &.closed {
        background-color: #f9fff9;
        a.task {
          color: #b8bec9;
        }
      }
    }
  }

  .time-table {
    .avatar {
      width: 30px;
      height: 30px;
    }

    .ant-row {
      padding: 2px 0;
    }

    .task-row {
      border-bottom: solid 1px #d8dee9;

      .finished_date {
        color: #7cb305;
      }

      &.closed {
        background-color: #f9fff9;
      }
    }

    .task-subject.closed .task{
      text-decoration: line-through;
      color: #d8dee9;
    }

    .today {
      color: #2f54eb;
    }

    .overdue {
      background-color: #ffccc7;
    }

    .due-today {
      background-color: #ffffdd;
    }

    .table-title {
      .subtitle {
        color: #a0a0a0;
        font-size: small;
      }
    }

    .date-item {
      padding-bottom: 2px;
    }

    .date-title {
      text-align: center;
    }

    .date-weekend {
      background-color: #ffdddd;
    }
  }

  .kanban {
    width: 100%;
    overflow-x: auto;

    .e-card-wrapper {
      max-height: 400px;
    }

    .e-header-title > div {
      width: 100%;
    }

    .header-title {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div {
        display: flex;
      }
    }

    .card {
      .ant-badge-status-text {
        font-size: 12px;
        line-height: 1;
      }

      .option-menu {
        border: none;
        margin-right: 5px;
        background-color: transparent;
      }
    }

    .e-card-content {
      padding-top: 0;
    }

    .card-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .card-epic {
      margin-bottom: 5px;
    }

    .card.blocked {
      background-color: rgba(228,64,87,.25);
    }
  }

  .roadmap-planning {
    .project {
      background-color: #f0f0f0;
    }
    .epic {
      background-color: #f9f9f9;
    }
  }

  .assignee-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    //padding: 5px;
    //border: dotted 1px black;
    //background-color: #d8dee9;
    //margin-bottom: 5px;
  }
}

.edit-user-story {
  .user-story-field { margin-bottom: 5px;}

  .assignee {
    margin-top: 10px;
  }
}

.taiga-assignee-container {

}

.taiga-kanban-task-list {
  max-width: 400px;
}

.taiga-kanban-task-list li {
  font-size: 11px;
  padding: 3px 6px;
}

.taiga-kanban-task-list li span {
  white-space: normal;
}

.todo {
  .task-list {
    .done {
        text-decoration: line-through;
    }

    .overdue,.overdue:hover {
      background-color: #fff2f0;
    }

    .today,.today:hover {
      background-color: #fffbe6;
    }
  }
}

.select-assignee-avatar {
  width: 28px;
  height: 28px;
}

.component-loading {
  width: 300px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-right {
  text-align: right;
  justify-content: right;
}

.vertical-center {
  display: flex;
  align-items: center;
}