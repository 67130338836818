.box {
  border: 1px solid #ebedf0;
  border-radius: 2px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 16px;
  transition: all .2s;
}

.dashboard .ant-btn {
  margin-bottom: 8px;
}

.emphasis {
  font-weight: bold;
}

.logo {
  height: 40px;
  color: #fff;
  font-size: 24px;
  padding-left: 5px;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.hueng .ant-row {
  margin-bottom: 5px;
}

.chanting {
  font-size: 20px;
}

.requirements .scenario li {
  list-style: none;
}

.requirements .scenario .keyword {
  font-weight: bold;
  display: inline-block;
  width: 50px;
}

.requirements .datatable {
  margin: 10px 30px;
}

.requirements .datatable th,.requirements .datatable td {
  border: solid 1px black;
  padding: 5px 10px;
}

.requirements .examples table {
  width: auto;
}

.requirements .examples .ant-table-wrapper {
  width: auto;
}

.requirements .quote {
  color: #2f54eb;
  font-weight: bold;
}

.requirements .param {
  color: #52c41a;
  font-weight: bold;
}

.requirements .examples .title {
  font-weight: bold;
}

.requirements .user_story {
  margin: 10px 0;
}

.requirements .user_story .ac {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 10px;
}

.requirements .user_story .description {
  padding: 10px;
}
.taiga .login-form {
  width: 300px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: azure;
  padding: 30px;
  border: solid 1px #adf0ff; }

.taiga table th, .taiga table td {
  padding: 5px !important; }

.taiga .projects-list {
  margin: 10px 0; }
  .taiga .projects-list .project .description-concat {
    position: relative;
    display: inline-block;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    /* (Number of lines you want visible) * (line-height) */
    line-height: 1.2em;
    text-align: justify; }

.taiga .epics .epic {
  background-color: azure; }

.taiga .epics .user-stories {
  padding: 20px 0; }

.taiga .project-summary .logo {
  margin-right: 12px; }

.taiga .sprints .us-progress .anticon {
  padding: 0 2px; }

.taiga .sprints .us-progress .avatar {
  max-width: 32px;
  max-height: 32px;
  margin: 0 2px; }

.taiga .sprints h4 {
  text-align: center;
  margin-top: 30px; }

.taiga .sprints h4.future {
  color: #52c41a; }

.taiga .sprints h4.past {
  color: #a6a6a6; }

.taiga .sprints h4.in-progress {
  color: #d9363e; }

.taiga .team .sprint .user_stories {
  padding: 0; }
  .taiga .team .sprint .user_stories li {
    list-style: none;
    margin: 2px;
    padding: 3px;
    background-color: #f9f9fb;
    border-bottom: 1px solid #d8dee9; }

.taiga .time-table-calendar .task-row {
  border-bottom: solid 1px #d8dee9; }
  .taiga .time-table-calendar .task-row.closed {
    background-color: #f9fff9; }
    .taiga .time-table-calendar .task-row.closed a.task {
      color: #b8bec9; }

.taiga .time-table .avatar {
  width: 30px;
  height: 30px; }

.taiga .time-table .ant-row {
  padding: 2px 0; }

.taiga .time-table .task-row {
  border-bottom: solid 1px #d8dee9; }
  .taiga .time-table .task-row .finished_date {
    color: #7cb305; }
  .taiga .time-table .task-row.closed {
    background-color: #f9fff9; }

.taiga .time-table .task-subject.closed .task {
  text-decoration: line-through;
  color: #d8dee9; }

.taiga .time-table .today {
  color: #2f54eb; }

.taiga .time-table .overdue {
  background-color: #ffccc7; }

.taiga .time-table .due-today {
  background-color: #ffffdd; }

.taiga .time-table .table-title .subtitle {
  color: #a0a0a0;
  font-size: small; }

.taiga .time-table .date-item {
  padding-bottom: 2px; }

.taiga .time-table .date-title {
  text-align: center; }

.taiga .time-table .date-weekend {
  background-color: #ffdddd; }

.taiga .kanban {
  width: 100%;
  overflow-x: auto; }
  .taiga .kanban .e-card-wrapper {
    max-height: 400px; }
  .taiga .kanban .e-header-title > div {
    width: 100%; }
  .taiga .kanban .header-title {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .taiga .kanban .header-title > div {
      display: flex; }
  .taiga .kanban .card .ant-badge-status-text {
    font-size: 12px;
    line-height: 1; }
  .taiga .kanban .card .option-menu {
    border: none;
    margin-right: 5px;
    background-color: transparent; }
  .taiga .kanban .e-card-content {
    padding-top: 0; }
  .taiga .kanban .card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .taiga .kanban .card-epic {
    margin-bottom: 5px; }
  .taiga .kanban .card.blocked {
    background-color: rgba(228, 64, 87, 0.25); }

.taiga .roadmap-planning .project {
  background-color: #f0f0f0; }

.taiga .roadmap-planning .epic {
  background-color: #f9f9f9; }

.taiga .assignee-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.edit-user-story .user-story-field {
  margin-bottom: 5px; }

.edit-user-story .assignee {
  margin-top: 10px; }

.taiga-kanban-task-list {
  max-width: 400px; }

.taiga-kanban-task-list li {
  font-size: 11px;
  padding: 3px 6px; }

.taiga-kanban-task-list li span {
  white-space: normal; }

.todo .task-list .done {
  text-decoration: line-through; }

.todo .task-list .overdue, .todo .task-list .overdue:hover {
  background-color: #fff2f0; }

.todo .task-list .today, .todo .task-list .today:hover {
  background-color: #fffbe6; }

.select-assignee-avatar {
  width: 28px;
  height: 28px; }

.component-loading {
  width: 300px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.text-right {
  text-align: right;
  justify-content: right; }

.vertical-center {
  display: flex;
  align-items: center; }

